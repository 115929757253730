import React, { useRef, useState } from "react";
import JSONDigger from "json-digger";
import OrganizationChart from "../components/ChartContainer";
import PositionNode from "./position-node";
import {ExcelRenderer} from 'react-excel-renderer';
import NumberFormat from 'react-number-format';
import ReactExport from "react-export-excel";
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert'; 

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const CustomNodeChart = () => {

  const SUPERIOR_KEY_COLUMN = 0;
  const POSITION_KEY_COLUMN = 1;
  const NAME_COLUMN = 2;
  const COST_CENTER_CODE_COLUMN = 3;
  const COST_CENTER_DESCRIPTION_COLUMN = 4;
  const AREA_COLUMN = 5;
  const TITLE_COLUMN = 6;
  const SALARY_COLUMN = 7;
  const CHARGES_COLUMN = 8;
  const MONTHLY_COST_COLUMN = 9;
  const CONTRACT_MODE_COLUMN = 10;
  const SITUATION_COLUMN = 11;
  const ALLOCATED_COLUMN = 12;
  const LOCALIZATION_COLUMN = 13;
  const COMPANY_COLUMN = 14;
  const VALIDATED_COLUMN = 15;

  const INITIAL_ROW = 1;

  const orgchart = useRef();

  const ROOT_KEY = -1;

  const removeNodeWrapper = () => {
    if (selectedNode && selectedNode.children){
        removeNodeDialog();
    } else {
        removePosition();
    }
  }

  const removeNodeDialog = () => {
    confirmAlert({
      message: 'Ao deletar esta posição, como deseja tratar as posições subordinadas?',
      buttons: [
        {
          label: 'Alocá-las no superior imediato',
          onClick: async () => {
            removePosition();
          }
        },
        {
          label: 'Deletar todas',
          onClick: async () => {
            removePositionAndChildren();
          }
        }
      ]
    });
  };

  const disallocateNodeWrapper = () => {
    if (selectedNode && selectedNode.children){
        disallocateNodeDialog();
    } else {
        disallocatePosition();
    }
  }

  const disallocateNodeDialog = () => {
    confirmAlert({
      message: 'Ao desalocar esta posição, como deseja tratar as posições subordinadas?',
      buttons: [
        {
          label: 'Alocá-las no superior imediato',
          onClick: async () => {
            let disallocatedPosition = await disallocatePosition();
            
            setOrphanNodes(orphanNodes.concat([disallocatedPosition]));
            populateHierarchyInfo({ ...dsDigger.ds }, orphanNodes.concat([disallocatedPosition]));
          }
        },
        {
          label: 'Desalocar todas',
          onClick: async () => {
              let disallocatedPositions = await disallocatePositionAndChildren();
              
              setOrphanNodes(orphanNodes.concat(disallocatedPositions));
              populateHierarchyInfo({ ...dsDigger.ds }, orphanNodes.concat(disallocatedPositions));
          }
        }
      ]
    });
  };

  const flatterize = (root) => {
    let positions = [];
    let queue = [root];

    while(queue.length) {
      let node = queue.pop();
      if (node.children) {
        queue = queue.concat(node.children);
      }
      node.index = positions.length;
      positions.push(node);
    }
    return positions;
  }

  const mapPositionsBySuperior = (positions) => {
    let positionsBySuperior = {};

    for (const position of positions) {
      if (position.superiorId) {
        positionsBySuperior[position.superiorId] = positionsBySuperior[position.superiorId] || [];
        positionsBySuperior[position.superiorId].push(position);
      } else {
        positionsBySuperior[ROOT_KEY] = position;
      }
    }

    return positionsBySuperior;
  }

  const totalSubordinates = (position) => {
    if (!position.children){
      return 0;
    }

    let subordinatesCount = position.children.length;
    for (const child of position.children) {
      subordinatesCount += totalSubordinates(child);
    }

    return subordinatesCount;
  }

  const totalSalary = (position) => {
    if (!position.children){
      return 0;
    }

    let salarySum = 0;
    for (const child of position.children) {
      salarySum += Number(child.salary) + Number(totalSalary(child));
    }

    return salarySum;
  }

  const totalCharges = (position) => {
    if (!position.children){
      return 0;
    }

    let chargesSum = 0;
    for (const child of position.children) {
      chargesSum += Number(child.charges) + Number(totalCharges(child));
    }

    return chargesSum;
  }

  const totalMonthlyCost = (position) => {
    if (!position.children){
      return 0;
    }

    let monthlyCostSum = 0;
    for (const child of position.children) {
      monthlyCostSum += Number(child.monthlyCost) + Number(totalMonthlyCost(child));
    }

    return monthlyCostSum;
  }

  const fixSuperior = (node, superiorId, originalSuperiorId) => {
    if (superiorId && originalSuperiorId){
      node.superiorId = superiorId;
      node.originalSuperiorId = originalSuperiorId;
    }

    if (node.children){
      for (const child of node.children){
        fixSuperior(child, node.id, node.originalId);
      }
    }
  }

  const populateDataExportResponse = (flatPositions, orphanNodes) => {
    let allPositions = flatPositions.concat(orphanNodes);
    setDataExportResponse(allPositions);
  }

  const populateHierarchyInfo = (datasource, updatedOrphanNodes) => {
    fixSuperior(datasource);

    let flatPositions = flatterize(datasource);

    for (const position of flatPositions) {
      position.totalSubordinates = totalSubordinates(position);
      position.subordinatesSalarySum = totalSalary(position);
      position.subordinatesChargesSum = totalCharges(position);
      position.subordinatesMonthlyCostSum = totalMonthlyCost(position);
    }

    setDS(datasource);
    setFlatPositions(flatPositions);
    populateDataExportResponse(flatPositions, updatedOrphanNodes || orphanNodes);

    return flatPositions;
  }

  const calculateHierarchySpan = (node, level = 2, superiorCountByLevel = {}, teamCountByLevel= {}) => {
    if (!node.children){
      return;
    }

    for (const child of node.children) {
      if (child.children && child.children.length){
        superiorCountByLevel[level] = superiorCountByLevel[level] || 0;
        superiorCountByLevel[level] = superiorCountByLevel[level] + 1;
        calculateHierarchySpan(child, level + 1, superiorCountByLevel, teamCountByLevel);
      } else {
        teamCountByLevel[level] = teamCountByLevel[level] || 0;
        teamCountByLevel[level] = teamCountByLevel[level] + 1;
      }
    }
  }

  const newNode = () => {
    return {
      superiorId: "",
      originalSuperiorId: "",
      id: "",
      originalId: "",
      name: "",
      costCenterCode: "",
      costCenterDescription: "",
      area: "",
      title: "",
      salary: "",
      formattedSalary: "",
      charges: "",
      formattedCharges: "",
      monthlyCost: "",
      formattedMonthlyCost: "",
      contractMode: "",
      situation: "",
      allocated: 1,
      localization: "",
      company: "",
      validated: ""
    }
  }

  const [ds, setDS] = useState();
  const [dataExportResponse, setDataExportResponse] = useState([]);
  const [positionCount, setPositionCount] = useState(0);
  const [selectedAction, setSelectedAction] = useState(0);
  const dsDigger = new JSONDigger(ds, "id", "children");

  const [editingNodes, setEditingNodes] = useState([newNode()]);
  const [selectedNode, setSelectedNode] = useState();
  const [selectedMultipleNode, setSelectedMultipleNode] = useState();
  const [selectedTab, setSelectedTab] = useState(1);
  const [spanDetailByLevel, setSpanDetailByLevel] = useState();
  const [newNodes, setNewNodes] = useState([newNode()]);
  const [orphanNodes, setOrphanNodes] = useState([]);
  const [flatPositions, setFlatPositions] = useState([]);

  const [panelCollapsed, setPanelCollapsed] = useState(false);

  const [validationErrors, setValidationErrors] = useState([]);
  const [actionHistory, setActionHistory] = useState([]);

  const [selectedLeafIndex, setSelectedLeafIndex] = useState(-1);


  const saveActionHistory = (actionName, orphanNodesAction) => {
    let lastAction = {actionName: actionName, previousState: _.cloneDeep(ds), orphanNodes: (orphanNodesAction ? orphanNodesAction : orphanNodes)};

    let updatedActions = actionHistory;
    actionHistory.push(lastAction);

    setActionHistory(updatedActions);
  }

  const undoLastAction = () => {
    if (!actionHistory.length) {
      return;
    }

    let lastAction = actionHistory.pop();
    setDS(lastAction.previousState);
    setOrphanNodes(lastAction.orphanNodes);
    setActionHistory(actionHistory);
  }


  const readSelectedMultipleNode = nodeData => {
        setSelectedMultipleNode(nodeData);
        setSelectedNode();

        let salarySum = 0;
        for (const child of nodeData.leaves) {
            salarySum += Number(child.salary);
        }
        nodeData.salarySum = salarySum;
        
        let chargesSum = 0;
        for (const child of nodeData.leaves) {
            chargesSum += Number(child.charges);
        }
        nodeData.chargesSum = chargesSum;
    
        let monthlyCostSum = 0;
        for (const child of nodeData.leaves) {
            monthlyCostSum += Number(child.monthlyCost);
        }
        nodeData.monthlyCostSum = monthlyCostSum;
  }

  const clickNodeFromChart = nodeData => {
      if (nodeData.id === 'CUSTOM_CHILD'){
          return;
      }
    
      setSelectedMultipleNode();
      setSelectedLeafIndex(-1);
      readSelectedNode(nodeData);
  }

  const clickNodeFromLeafList = (nodeData, leafIndex) => {
    setSelectedLeafIndex(leafIndex);
    readSelectedNode(nodeData);
  }

  const readSelectedNode = nodeData => {
      setSelectedNode(nodeData);

      let startLevel = 1;

      let positionById = {};
      for (const node of flatterize(ds)) {
        positionById[node.id] = node;
      }

      let superiorId = nodeData.superiorId
      while (superiorId){
        superiorId = positionById[superiorId].superiorId
        startLevel ++;
      }

      let superiorCountByLevel = {};
      superiorCountByLevel[startLevel] = (nodeData.children && nodeData.children.length ? 1 : 0);
      let teamCountByLevel = {};
      teamCountByLevel[startLevel] = 0;

      calculateHierarchySpan(nodeData, startLevel + 1, superiorCountByLevel, teamCountByLevel);

      let spanDetailByLevel = {};
      for (let level = startLevel; level < Math.max(Object.keys(superiorCountByLevel).length, Object.keys(teamCountByLevel).length) + startLevel; level ++){
        spanDetailByLevel[level] = {
          superior: superiorCountByLevel[level] || 0,
          team: teamCountByLevel[level] || 0,
          span: ((superiorCountByLevel[level+1] || 0) + (teamCountByLevel[level+1] || 0)) / (superiorCountByLevel[level] || 0)
        };
      }
      setSpanDetailByLevel(spanDetailByLevel);

      editingNodes[0].superiorId = nodeData.superiorId;
      editingNodes[0].originalSuperiorId = nodeData.originalSuperiorId;
      editingNodes[0].id = nodeData.id;
      editingNodes[0].originalId = nodeData.originalId;
      editingNodes[0].name = nodeData.name;
      editingNodes[0].costCenterCode = nodeData.costCenterCode;
      editingNodes[0].costCenterDescription = nodeData.costCenterDescription;
      editingNodes[0].area = nodeData.area;
      editingNodes[0].title = nodeData.title;
      editingNodes[0].salary = nodeData.salary;
      editingNodes[0].formattedSalary = nodeData.formattedSalary;
      editingNodes[0].charges = nodeData.charges;
      editingNodes[0].formattedCharges = nodeData.formattedCharges;
      editingNodes[0].monthlyCost = nodeData.monthlyCost;
      editingNodes[0].formattedMonthlyCost = nodeData.formattedMonthlyCost;
      editingNodes[0].contractMode = nodeData.contractMode;
      editingNodes[0].situation = nodeData.situation;
      editingNodes[0].allocated = nodeData.allocated;
      editingNodes[0].localization = nodeData.localization;
      editingNodes[0].company = nodeData.company;
      editingNodes[0].validated = nodeData.validated;

      setEditingNodes([...editingNodes]);
  };

  const onSelectedPositionValueChange = (e, value, index) => {
    editingNodes[index][value] = e.target.value;
    setEditingNodes([...editingNodes]);
  }

  const onSelectedPositionMonetaryValueChange = (e, value, index) => {
    editingNodes[index][value] = e.floatValue;
  }

  const clearSelectedNode = () => {
    setSelectedNode();
  };

  const onIdChange = (e, index) => {
    newNodes[index].originalId = e.target.value;
    newNodes[index].id = sanitize(newNodes[index].originalId);
    setNewNodes([...newNodes]);
  };

  const onNameChange = (e, index) => {
    newNodes[index].name = e.target.value;
    setNewNodes([...newNodes]);
  };

  const onCostCenterCodeChange = (e, index) => {
    newNodes[index].costCenterCode = e.target.value;
    setNewNodes([...newNodes]);
  };

  const onCostCenterDescriptionChange = (e, index) => {
    newNodes[index].costCenterDescription = e.target.value;
    setNewNodes([...newNodes]);
  };

  const onAreaChange = (e, index) => {
    newNodes[index].area = e.target.value;
    setNewNodes([...newNodes]);
  };

  const onSalaryChange = (e, index) => {
    newNodes[index].salary = e.target.value;
    setNewNodes([...newNodes]);
  };

  const onChargesChange = (e, index) => {
    newNodes[index].charges = e.target.value;
    setNewNodes([...newNodes]);
  };

  const onMonthlyCostChange = (e, index) => {
    newNodes[index].monthlyCost = e.target.value;
    setNewNodes([...newNodes]);
  };

  const onTitleChange = (e, index) => {
    newNodes[index].title = e.target.value;
    setNewNodes([...newNodes]);
  };

  const onContractModeChange = (e, index) => {
    newNodes[index].contractMode = e.target.value;
    setNewNodes([...newNodes]);
  };

  const onSituationChange = (e, index) => {
    newNodes[index].situation = e.target.value;
    setNewNodes([...newNodes]);
  };

  const onLocalizationChange = (e, index) => {
    newNodes[index].localization = e.target.value;
    setNewNodes([...newNodes]);
  };

  const onCompanyChange = (e, index) => {
    newNodes[index].company = e.target.value;
    setNewNodes([...newNodes]);
  };

  const getNewNodes = () => {
    const nodes = [];
    let childCount = 1;
    for (const node of newNodes) {
      if (node.name && node.title && node.originalId){
        nodes.push({ ...node });
        childCount ++;
      }
    }
    setPositionCount(positionCount + childCount);
    return nodes;
  };

  const updateNode = async () => {
    let updatedNode = editingNodes[0];
    if (updatedNode.tempSalary){
      updatedNode.salary = updatedNode.tempSalary;
      updatedNode.formattedSalary = Number(updatedNode.tempSalary).toFixed(2);
    }
    if (updatedNode.tempCharges){
      updatedNode.charges = updatedNode.tempCharges;
      updatedNode.formattedCharges = Number(updatedNode.tempCharges).toFixed(2);
    }
    if (updatedNode.tempMonthlyCost){
      updatedNode.monthlyCost = updatedNode.tempMonthlyCost;
      updatedNode.formattedMonthlyCost = Number(updatedNode.tempMonthlyCost).toFixed(2);
    }

    await dsDigger.updateNode(updatedNode);
    setDS({ ...dsDigger.ds });
    let flatPositions = flatterize(ds);

    setFlatPositions(flatPositions);
    populateDataExportResponse(flatPositions, orphanNodes);
  }

  const addChildNodes = async () => {
    let positionKeys = [];

    for (const node of flatterize(ds)) {
      positionKeys.push(String(node.originalId).toLowerCase());
      positionKeys.push(String(node.id).toLowerCase());
    }

    let newPosition = newNodes[newNodes.length-1];
    if (positionKeys.indexOf(String(newPosition.id).toLowerCase()) !== -1){
      alert('A chave informada para a posição já está sendo utilizada');
      return;
    }

    let mandatoryFieldsFilled = newPosition.name && newPosition.title && newPosition.id;
    if (!mandatoryFieldsFilled){
      alert('É preciso preencher os campos obrigatórios');
      return;
    }

    saveActionHistory('Adicionar posição ' + newPosition.id);

    setNewNodes(prevNewNodes => [...prevNewNodes, newNode()]);

    await dsDigger.addChildren(selectedNode.id, getNewNodes());
    await populateHierarchyInfo({ ...dsDigger.ds });
    setNewNodes([newNode()]);
  };

  const allocateOrphanNode = (orphanNode) => {
    if (!orphanNodes || orphanNodes.length === 0){
        return;
    }
    
    saveActionHistory('Alocar posição ' + orphanNode.id, orphanNodes);

    let orphanNodesUpdated = _.reject(orphanNodes, {id: orphanNode.id});

    setOrphanNodes(orphanNodesUpdated);

    orphanNode.allocated = 1;
    orphanNode.originalSuperiorId = selectedNode.originalId;

    dsDigger.addChildren(selectedNode.id, orphanNode);
    
    populateHierarchyInfo({ ...dsDigger.ds });
  };

  const removePosition = async () => {
    if (!selectedNode.superiorId){
      alert('Não é permitido remover a posição raíz');
      return;
    }

    saveActionHistory('Remover posição ' + selectedNode.id);
  
    if (selectedNode.children){
        for (const child of selectedNode.children) {
            child.superiorId = selectedNode.superiorId;
            child.originalSuperiorId = selectedNode.originalSuperiorId;
        }
    
        await dsDigger.addChildren(selectedNode.superiorId, selectedNode.children);
    }

    await dsDigger.removeNodes(selectedNode.id);
    await populateHierarchyInfo({ ...dsDigger.ds });
    setSelectedNode();
    setNewNodes([newNode()]);
  };

  const removePositionAndChildren = async () => {
    if (!selectedNode.superiorId){
      alert('Não é permitido remover a posição raíz');
      return;
    }

    saveActionHistory('Remover posição ' + selectedNode.id);

    await dsDigger.removeNodes(selectedNode.id);
    await populateHierarchyInfo({ ...dsDigger.ds });
    setSelectedNode();
    setNewNodes([newNode()]);
  };

  const disallocatePosition = async () => {
    if (!selectedNode.superiorId){
        alert('Não é permitido desalocar a posição raíz');
        return;
    }

    saveActionHistory('Desalocar posição ' + selectedNode.id);

    let positionNode = _.cloneDeep(selectedNode);
    positionNode.superiorId = null;
    positionNode.originalSuperiorId = null;
    positionNode.mixedChildren = [];
    positionNode.allocated = 0;
    positionNode.children = [];

    if (selectedNode.children){
        for (const child of selectedNode.children) {
            child.superiorId = selectedNode.superiorId;
            child.originalSuperiorId = selectedNode.originalSuperiorId;
        }
    
        await dsDigger.addChildren(selectedNode.superiorId, selectedNode.children);
    }
    
    await dsDigger.removeNodes(selectedNode.id);
    await populateHierarchyInfo({ ...dsDigger.ds });
    setSelectedNode();
    setNewNodes([newNode()]);

    return positionNode;
  }

  const disallocatePositionAndChildren = async () => {
    if (!selectedNode.superiorId){
        alert('Não é permitido desalocar a posição raíz');
        return;
    }

    saveActionHistory('Desalocar posição ' + selectedNode.id);

    let flatDisallocatedPositions = flatterize(selectedNode);

    await dsDigger.removeNodes(selectedNode.id);
    await populateHierarchyInfo({ ...dsDigger.ds });
    setSelectedNode();
    setNewNodes([newNode()]);

    for (const flatChild of flatDisallocatedPositions){
        flatChild.superiorId = null;
        flatChild.originalSuperiorId = null;
        flatChild.mixedChildren = [];
        flatChild.allocated = 0;
        flatChild.children = [];
    }
    
    return flatDisallocatedPositions;
  }


  const sanitize = (value) => {
    if (!value) {
      return value;
    }

    value = String(value).replace('/', '');
    value = String(value).replace(' ', '');

    return 'N' + value;
  }
  
  const validateDuplicates = (nodes) => {
    let errors = [];

    let nodeKeys = [];
    for (const node of nodes){
        if (nodeKeys.indexOf(node.id) > -1){
            errors.push("Linha " + (node.line +1) + ": A chave informada para a posição está duplicada")
        } else {
            nodeKeys.push(node.id);
        }
    }

    return errors;
  };

  const validateOrphanNodes = (nodes) => {
    let errors = [];

    for (const node of nodes) {
      if (node.originalSuperiorId){
        errors.push("Linha " + (node.line +1) + ": Posições desalocadas não podem ter a coluna de superior preenchida")
      }
      if (!node.originalId){
        errors.push("Linha " + (node.line +1) + ": Código da posição não pode vazia")
      }
    }

    return errors;
  }

  const validateNodes = (nodes) => {
    let errors = [];

    let positionKeys = [];
    for (const node of nodes) {
      positionKeys.push(node.originalId);
    }

    let rootCount = 0;
    for (const node of nodes) {
      if (!node.originalSuperiorId){
        rootCount ++;
      }
      if (!node.originalId){
        errors.push("Linha " + (node.line +1) + ": Código da posição não pode vazia")
      }
      if (node.originalSuperiorId && positionKeys.indexOf(node.originalSuperiorId) === -1){
        errors.push("Linha " + (node.line +1) + ": Código da posição superior não encontrada")
      }
    }

    let positionById = {};
    for (const node of nodes) {
      positionById[node.originalId] = node;
    }

    for (const node of nodes) {
      let visitedNodeIds = [node.originalId];
      let superior = positionById[node.originalSuperiorId];
      while (superior) {
        if (visitedNodeIds.indexOf(superior.originalId) !== -1){
          errors.push("Foi encontrado um ciclo hierárquico a partir da posição de chave: " + superior.originalId);
          break;
        } else {
          visitedNodeIds.push(superior.originalId);
          superior = positionById[superior.originalSuperiorId];
        }
      }
    }

    if (rootCount !== 1){
      errors.push("Deve existar uma e somente uma posição sem superior");
    }

    return errors;
  }

  const fileHandler = async(event) => {
      setDS();
      let fileObj = event.target.files[0];

      ExcelRenderer(fileObj, (err, resp) => {
          if (err) {
              console.log(err);
              return;
          } else {
            let nodes = [];
            let orphanNodes = [];
            for (let i = INITIAL_ROW; i < resp.rows.length; i ++) {
              if (resp.rows[i][POSITION_KEY_COLUMN]){
                let node = {
                  superiorId: sanitize(resp.rows[i][SUPERIOR_KEY_COLUMN]),
                  originalSuperiorId: resp.rows[i][SUPERIOR_KEY_COLUMN],
                  id: sanitize(resp.rows[i][POSITION_KEY_COLUMN]),
                  originalId: resp.rows[i][POSITION_KEY_COLUMN],
                  name: resp.rows[i][NAME_COLUMN],
                  costCenterCode: resp.rows[i][COST_CENTER_CODE_COLUMN],
                  costCenterDescription: resp.rows[i][COST_CENTER_DESCRIPTION_COLUMN],
                  area: resp.rows[i][AREA_COLUMN],
                  title: resp.rows[i][TITLE_COLUMN],
                  salary: Number(resp.rows[i][SALARY_COLUMN] || 0),
                  formattedSalary: Number(resp.rows[i][SALARY_COLUMN] || 0).toFixed(2),
                  charges: Number(resp.rows[i][CHARGES_COLUMN] || 0),
                  formattedCharges: Number(resp.rows[i][CHARGES_COLUMN] || 0).toFixed(2),
                  monthlyCost: Number(resp.rows[i][MONTHLY_COST_COLUMN] || 0),
                  formattedMonthlyCost: Number(resp.rows[i][MONTHLY_COST_COLUMN] || 0).toFixed(2),
                  contractMode: resp.rows[i][CONTRACT_MODE_COLUMN],
                  situation: resp.rows[i][SITUATION_COLUMN],
                  allocated: resp.rows[i][ALLOCATED_COLUMN],
                  localization: resp.rows[i][LOCALIZATION_COLUMN],
                  company: resp.rows[i][COMPANY_COLUMN],
                  validated: resp.rows[i][VALIDATED_COLUMN],
                  line: i
                }
                
                if (node.allocated === '' || node.allocated === '0' || node.allocated === 0){
                    orphanNodes.push(node);
                } else {
                    nodes.push(node);
                }
              }
            }

            
            let errors = validateNodes(nodes);
            let orphanErrors = validateOrphanNodes(orphanNodes);
            let duplicatedErrors = validateDuplicates(nodes.concat(orphanNodes));

            let allErrors = errors.concat(orphanErrors.concat(duplicatedErrors));
            setValidationErrors(allErrors);

            if (allErrors.length === 0) {
              let positionsBySuperior = mapPositionsBySuperior(nodes);
              let positionById = {};
              for (const node of nodes) {
                node.children = positionsBySuperior[node.id];
                positionById[node.id] = node;
              }

              let root = positionsBySuperior[-1];

              populateHierarchyInfo(root);
              setDS(root);
              setOrphanNodes(orphanNodes);
              
              let flatPositions = flatterize(root);
              setFlatPositions(flatPositions);
              populateDataExportResponse(flatPositions, orphanNodes);
            }

          }
      });
    }


    
    const exportToPNG = () => {
        orgchart.current.exportToPNG();
    };








    







    return (
      <div className="m-flex full-container">
        <div className="chart-wrapper m-flex"  style={{ width: panelCollapsed ? '100%' : '80%' }}>
          {!ds && (
              <div className="no-content">
                <div>Carregue as posições pelo painel ao lado</div>
                <p>Use as colunas na seguinte ordem: Chave Gestor |Chave colab | Nome | Cód. CC | Desc. C.C | Área | Cargo | Salario Nominal | Encargos | Custo mensal | Regime Contratação | Situação | Alocado (0 ou 1) | Localidade | Empresa | Validado (0 ou 1) </p>

                <div className="validation-errors">
                  {validationErrors && validationErrors.map((error, index) => (
                    <p key={index}>{error}</p>
                  ))}
                </div>
              </div>
          )}
          { ds && (
              
                <OrganizationChart
                    ref={orgchart}
                    datasource={ds}
                    flatPositions={flatPositions}
                    collapsible={true}
                    pan={true}
                    zoom={false}
                    saveActionHistory={saveActionHistory}
                    undoLastAction={undoLastAction}
                    actionHistory={actionHistory}
                    onClickNode={clickNodeFromChart}
                    onClickMultipleNode={readSelectedMultipleNode}
                    onDropNode={populateHierarchyInfo}
                    NodeTemplate={PositionNode}
                    chartClass="myChart"
                    draggable={true}
                    onClickChart={clearSelectedNode}
                />
            
          )}
        </div>

        <div className="panel-holder">

          <div className="panel-toggle" onClick={() => setPanelCollapsed(!panelCollapsed)}>
            {panelCollapsed && (
                <span>&#60;</span>
            )}
            {!panelCollapsed && (
                <span>&#62;</span>
            )}
          </div>
          <div className="actions-panel" style={{ display: panelCollapsed ? 'none' : 'block' }}>
            <div className="panel m-flex ai-center jc-space-between">
              <div className="load-positions">
                <div className="introduction-content">Carregar posições</div>
                <input type="file" onChange={fileHandler.bind(this)} style={{"padding":"10px"}} />

                <div className="m-flex ai-center jc-space-between margin-top-10">
                    <div>
                        <ExcelFile element={<button>Exportar</button>}>
                        <ExcelSheet data={dataExportResponse} name="Organograma">
                            <ExcelColumn label="Chave Gestor" value="originalSuperiorId"/>
                            <ExcelColumn label="Chave colab" value="originalId"/>
                            <ExcelColumn label="Nome" value="name"/>
                            <ExcelColumn label="Cód CC" value="costCenterCode"/>
                            <ExcelColumn label="Desc CC" value="costCenterDescription"/>
                            <ExcelColumn label="Área" value="area"/>
                            <ExcelColumn label="Cargo" value="title"/>
                            <ExcelColumn label="Salario nominal" value="formattedSalary"/>
                            <ExcelColumn label="Encargos" value="formattedCharges"/>
                            <ExcelColumn label="Custo mensal" value="formattedMonthlyCost"/>
                            <ExcelColumn label="Regime contratação" value="contractMode"/>
                            <ExcelColumn label="Situação" value="situation"/>
                            <ExcelColumn label="Alocado" value="allocated"/>
                            <ExcelColumn label="Localização" value="localization"/>
                            <ExcelColumn label="Empresa" value="company"/>
                            <ExcelColumn label="Validado" value="validated"/>
                        </ExcelSheet>
                        </ExcelFile>
                    </div>

                    {ds && (
                        <div className="export-png-section">
                            <button onClick={exportToPNG} style={{ marginLeft: "2rem" }}>Export (PNG)</button>
                        </div>
                    )}
                </div>
              </div>
              
            </div>


            <hr/>

            <div className="edit-chart-wrapper">
              {selectedMultipleNode && (
                  <div className="toolbar-holder">
                    <div className="margin-top-5">
                        <div>Salário da área</div>
                        <div><b><NumberFormat value={selectedMultipleNode.salarySum} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'R$ '}/></b></div>
                    </div>
                    <div className="margin-top-5">
                        <div>Custo mensal da área</div>
                        <div><b><NumberFormat value={selectedMultipleNode.monthlyCostSum} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'R$ '}/></b></div>
                    </div>
                    <div className="margin-top-5">
                        <div>Encargos da área</div>
                        <div><b><NumberFormat value={selectedMultipleNode.chargesSum} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'R$ '}/></b></div>
                    </div>
                    <hr/>
                    {selectedMultipleNode.leaves.map((leaf, index) => (
                        <div key={index} className={'leaf-list-item ' + (selectedLeafIndex === index ? 'leaf-list-item-selected' : '')} >
                            <a onClick={() => clickNodeFromLeafList(leaf, index)}>{leaf.originalId} - {leaf.name}</a>
                        </div>
                    ))}
                    <hr/>
                  </div>
              )}

              {selectedNode && (
                  <div className="toolbar-holder">
                    <div className="toolbar-menu">
                      <div className="m-flex ai-center">
                        <div className={"position-tab " + (selectedTab === 1 ? 'position-tab-selected' : '')} onClick={()=> setSelectedTab(1)}>Detalhes da posição</div>
                        <div className={"position-tab " + (selectedTab === 2 ? 'position-tab-selected' : '')} onClick={()=> setSelectedTab(2)}>Ações</div>
                      </div>
                      {selectedTab === 1 && (
                          <>
                            {editingNodes && editingNodes.map((node, index) => (
                                <div key={index}>
                                  <div className="m-flex ai-center margin-top-5">
                                    <div className="editing-label">Nome: </div>
                                    <div className="editing-input"><input type="text" placeholder="Nome" value={node.name} onChange={e => onSelectedPositionValueChange(e, 'name', index)}/></div>
                                  </div>
                                  <div className="m-flex ai-center margin-top-5">
                                    <div className="editing-label">Cargo: </div>
                                    <div className="editing-input"><input type="text" placeholder="Cargo" value={node.title} onChange={e => onSelectedPositionValueChange(e, 'title', index)}/></div>
                                  </div>
                                  <div className="m-flex ai-center margin-top-5">
                                    <div className="editing-label">Cód C.C.: </div>
                                    <div className="editing-input"><input type="text" placeholder="Cód C.C." value={node.costCenterCode} onChange={e => onSelectedPositionValueChange(e, 'costCenterCode', index)}/></div>
                                  </div>
                                  <div className="m-flex ai-center margin-top-5">
                                    <div className="editing-label">Desc. C.C.: </div>
                                    <div className="editing-input"><input type="text" placeholder="Desc. C.C." value={node.costCenterDescription} onChange={e => onSelectedPositionValueChange(e, 'costCenterDescription', index)}/></div>
                                  </div>
                                  <div className="m-flex ai-center margin-top-5">
                                    <div className="editing-label">Área: </div>
                                    <div className="editing-input"><input type="text" placeholder="Area" value={node.area} onChange={e => onSelectedPositionValueChange(e, 'area', index)}/></div>
                                  </div>
                                  <div className="m-flex ai-center margin-top-5">
                                    <div className="editing-label">Situação: </div>
                                    <div className="editing-input"><input type="text" placeholder="Situação" value={node.situation} onChange={e => onSelectedPositionValueChange(e, 'situation', index)}/></div>
                                  </div>
                                  <div className="m-flex ai-center margin-top-5">
                                    <div className="editing-label">Localização: </div>
                                    <div className="editing-input"><input type="text" placeholder="Localização" value={node.localization} onChange={e => onSelectedPositionValueChange(e, 'localization', index)}/></div>
                                  </div>
                                  <div className="m-flex ai-center margin-top-5">
                                    <div className="editing-label">Contrato: </div>
                                    <div className="editing-input"><input type="text" placeholder="Contrato" value={node.contractMode} onChange={e => onSelectedPositionValueChange(e, 'contractMode', index)}/></div>
                                  </div>
                                  <div className="m-flex ai-center margin-top-5">
                                    <div className="editing-label">Empresa: </div>
                                    <div className="editing-input"><input type="text" placeholder="Empresa" value={node.company} onChange={e => onSelectedPositionValueChange(e, 'company', index)}/></div>
                                  </div>
                                  <div className="m-flex ai-center margin-top-5">
                                    <div className="editing-label">Salário: </div>
                                    <div className="editing-input">
                                      <NumberFormat value={node.salary} displayType={'input'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}
                                                    fixedDecimalScale={true} prefix={'R$ '} onValueChange={e => onSelectedPositionMonetaryValueChange(e, 'tempSalary', index)}/>
                                    </div>
                                  </div>
                                  <div className="m-flex ai-center margin-top-5">
                                    <div className="editing-label">Encargos: </div>
                                    <div className="editing-input">
                                      <NumberFormat value={node.charges} displayType={'input'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}
                                                    fixedDecimalScale={true} prefix={'R$ '} onValueChange={e => onSelectedPositionMonetaryValueChange(e, 'tempCharges', index)}/>
                                    </div>
                                  </div>
                                  <div className="m-flex ai-center margin-top-5">
                                    <div className="editing-label">Custo mensal: </div>
                                    <div className="editing-input">
                                      <NumberFormat value={node.monthlyCost} displayType={'input'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}
                                                    fixedDecimalScale={true} prefix={'R$ '} onValueChange={e => onSelectedPositionMonetaryValueChange(e, 'tempMonthlyCost', index)}/>
                                    </div>
                                  </div>
                                </div>
                            ))}
                            <div className="margin-top-5">
                              <button className="action-button-minor" onClick={updateNode}>
                                Salvar
                              </button>
                            </div>
                            <hr/>
                            <div className="margin-top-5">
                              <div>Salário da área</div>
                              <div><b><NumberFormat value={selectedNode.subordinatesSalarySum} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'R$ '}/></b></div>
                            </div>
                            <div className="margin-top-5">
                              <div>Custo mensal da área</div>
                              <div><b><NumberFormat value={selectedNode.subordinatesMonthlyCostSum} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'R$ '}/></b></div>
                            </div>
                            <div className="margin-top-5">
                              <div>Encargos da área</div>
                              <div><b><NumberFormat value={selectedNode.subordinatesChargesSum} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'R$ '}/></b></div>
                            </div>
                            <hr/>
                            <div className="hierarchy-span-details">
                              <table>
                                <thead>
                                    <tr>
                                        <th>Nível</th>
                                        <th>Gestores</th>
                                        <th>Equipes</th>
                                        <th>Span</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {spanDetailByLevel && Object.keys(spanDetailByLevel).map((level, index) =>(
                                    <tr key={index}>
                                      <td>{level}</td>
                                      <td>{spanDetailByLevel[level].superior}</td>
                                      <td>{spanDetailByLevel[level].team}</td>
                                      <td>{(spanDetailByLevel[level].span || 0).toFixed(1)}</td>
                                    </tr>
                                ))}
                                </tbody>
                              </table>
                            </div>
                          </>
                      )}

                      {selectedTab === 2 && (
                          <>
                            <div className="new-nodes">
                              <p className="action-item" onClick={() => setSelectedAction(selectedAction === 1 ? 0 : 1)}>1. Incluir novas posições</p>
                              {selectedAction === 1 && newNodes && newNodes.map((node, index) => (
                                  <div className="action-content">
                                    <div key={index} className="new-nodes-row">
                                      <input
                                          type="text"
                                          placeholder="Chave *"
                                          value={node.originalId}
                                          required
                                          onChange={e => onIdChange(e, index)}/> <br/>
                                      <input
                                          type="text"
                                          placeholder="Nome *"
                                          value={node.name}
                                          required
                                          onChange={e => onNameChange(e, index)}/> <br/>
                                      <input
                                          type="text"
                                          placeholder="Cargo *"
                                          value={node.title}
                                          required
                                          onChange={e => onTitleChange(e, index)}/> <br/>
                                      <input
                                          type="text"
                                          placeholder="Cód C.C"
                                          value={node.costCenterCode}
                                          onChange={e => onCostCenterCodeChange(e, index)}/> <br/>
                                      <input
                                          type="text"
                                          placeholder="Desc C.C"
                                          value={node.costCenterDescription}
                                          onChange={e => onCostCenterDescriptionChange(e, index)}/> <br/>
                                      <input
                                          type="text"
                                          placeholder="Area"
                                          value={node.area}
                                          onChange={e => onAreaChange(e, index)}/> <br/>
                                      <input
                                          type="text"
                                          placeholder="Salário"
                                          value={node.salary}
                                          onChange={e => onSalaryChange(e, index)}/> <br/>
                                      <input
                                          type="text"
                                          placeholder="Encargos"
                                          value={node.charges}
                                          onChange={e => onChargesChange(e, index)}/> <br/>
                                      <input
                                          type="text"
                                          placeholder="Custo total"
                                          value={node.monthlyCost}
                                          onChange={e => onMonthlyCostChange(e, index)}/> <br/>
                                      <input
                                          type="text"
                                          placeholder="Contrato"
                                          value={node.contractMode}
                                          onChange={e => onContractModeChange(e, index)}/> <br/>
                                      <input
                                          type="text"
                                          placeholder="Situação"
                                          value={node.situation}
                                          onChange={e => onSituationChange(e, index)}/> <br/>
                                      <input
                                          type="text"
                                          placeholder="Localização"
                                          value={node.localization}
                                          onChange={e => onLocalizationChange(e, index)}/> <br/>
                                      <input
                                          type="text"
                                          placeholder="Empresa"
                                          value={node.company}
                                          onChange={e => onCompanyChange(e, index)}/> <br/>
                                    </div>
                                    <div className="margin-top-10">
                                      <button className="action-button-minor" onClick={addChildNodes}>
                                        Adicionar subordinado
                                      </button>
                                    </div>
                                  </div>
                              ))}
                            </div>
                            <p className="action-item" onClick={() => {setSelectedAction(0); removeNodeWrapper();}}>2. Remover posição/hierarquia</p>

                            <p className="action-item" onClick={() => {setSelectedAction(0); disallocateNodeWrapper()}}>3. Desalocar posição/hierarquia</p>
                        
                            <p className="action-item" onClick={() => setSelectedAction(selectedAction === 4 ? 0 : 4)}>4. Alocar subordinado desalocado</p>
                            {selectedAction === 4 && (
                                <div className="action-content">
                                  {orphanNodes && orphanNodes.map((node, index) => (
                                    <div className="orphan-node-selection-item" key={node.id}>
                                        <a onClick={() => allocateOrphanNode(node)}>{node.id} - {node.name}</a>
                                    </div>
                                  ))}
                                </div>
                            )}
                          </>
                      )}
                    </div>
                  </div>
              )}
            </div>
          </div>
        </div>
      </div>
  );
};

export default CustomNodeChart;
