import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle
} from "react";
import PropTypes from "prop-types";
import { selectNodeService } from "./service";
import JSONDigger from "json-digger";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ChartNode from "./ChartNode";
import "./ChartContainer.css";
import ReactTooltip from 'react-tooltip';
import Modal from 'react-modal';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table'
import _ from 'lodash';

const propTypes = {
  datasource: PropTypes.object.isRequired,
  flatPositions: PropTypes.array,
  pan: PropTypes.bool,
  zoom: PropTypes.bool,
  containerClass: PropTypes.string,
  chartClass: PropTypes.string,
  NodeTemplate: PropTypes.elementType,
  draggable: PropTypes.bool,
  collapsible: PropTypes.bool,
  multipleSelect: PropTypes.bool,
  onClickNode: PropTypes.func,
  onClickMultipleNode: PropTypes.func,
  onDropNode: PropTypes.func,
  onClickChart: PropTypes.func,
  saveActionHistory: PropTypes.func,
  undoLastAction: PropTypes.func,
  actionHistory: PropTypes.array
};

const defaultProps = {
  pan: false,
  zoom: false,
  zoomValue: 1,
  containerClass: "",
  chartClass: "",
  draggable: false,
  collapsible: true,
  multipleSelect: false
};

const ChartContainer = forwardRef(
  (
    {
      datasource,
      flatPositions,
      pan,
      zoom,
      containerClass,
      chartClass,
      NodeTemplate,
      draggable,
      collapsible,
      multipleSelect,
      onClickNode,
      onClickMultipleNode,
      onDropNode,
      onClickChart,
      saveActionHistory,
      undoLastAction,
      actionHistory
    },
    ref
  ) => {
    const container = useRef();
    const chart = useRef();
    const downloadButton = useRef();

    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [transform, setTransform] = useState("");
    const [panning, setPanning] = useState(false);
    const [cursor, setCursor] = useState("default");
    const [exporting, setExporting] = useState(false);
    const [dataURL, setDataURL] = useState("");
    const [download, setDownload] = useState("");
    const [zoomValue, setZoomValue] = useState(1);
    const [settingsByNode, setSettingsByNode] = useState({});


















    





    Modal.setAppElement("#app")

    const [modalIsOpen,setIsOpen] = React.useState(false);
    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        //subtitle.style.color = '#f00';
    }

    function closeModal(){
        setIsOpen(false);
    }





    








// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)
  
    return (
      <div className="m-flex search-fuzzy">
        <div>Buscar:{' '}</div>
        <input value={value || ""} onChange={e => { setValue(e.target.value); onChange(e.target.value); }} placeholder={`${count} registros...`}/>
      </div>
    )
  }
  
  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
  
    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Buscando ${count} registros...`}
      />
    )
  }
  
  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set()
      preFilteredRows.forEach(row => {
        options.add(row.values[id])
      })
      return [...options.values()]
    }, [id, preFilteredRows])
  
    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    )
  }
  
  // This is a custom filter UI that uses a
  // slider to set the filter value between a column's
  // min and max values
  function SliderColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the min and max
    // using the preFilteredRows
  
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      preFilteredRows.forEach(row => {
        min = Math.min(row.values[id], min)
        max = Math.max(row.values[id], max)
      })
      return [min, max]
    }, [id, preFilteredRows])
  
    return (
      <>
        <input
          type="range"
          min={min}
          max={max}
          value={filterValue || min}
          onChange={e => {
            setFilter(parseInt(e.target.value, 10))
          }}
        />
        <button onClick={() => setFilter(undefined)}>Off</button>
      </>
    )
  }
  
  // This is a custom UI for our 'between' or number range
  // filter. It uses two number boxes and filters rows to
  // ones that have values between the two
  function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      preFilteredRows.forEach(row => {
        min = Math.min(row.values[id], min)
        max = Math.max(row.values[id], max)
      })
      return [min, max]
    }, [id, preFilteredRows])
  
    return (
      <div>
        <input value={filterValue[0] || ''} type="number"
          onChange={e => {const val = e.target.value; setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])}}
          placeholder={`De`}
          style={{width: '60px', marginRight: '0.5rem'}}
        />
        -
        <input value={filterValue[1] || ''}
          type="number" onChange={e => {const val = e.target.value; setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])}}
          placeholder={`Até`}
          style={{width: '60px', marginLeft: '0.5rem'}}
        />
      </div>
    )
  }
  

 
                    
const columns = React.useMemo(
    () => [
      {
        Header: 'Dados das posições',
        columns: [
          { Header: 'Chave colab', accessor: 'originalId' },
          { Header: 'Nome', accessor: 'name', filter: 'fuzzyText' },
          { Header: 'Cód CC', accessor: 'costCenterCode', filter: 'fuzzyText' },//Filter: SliderColumnFilter, filter: 'equals',
          { Header: 'Desc CC', accessor: 'costCenterDescription' },  //Filter: NumberRangeColumnFilter,//filter: 'between',
          { Header: 'Area', accessor: 'area' }, //Filter: SelectColumnFilter, //filter: 'includes',
          { Header: 'Cargo', accessor: 'title' }, //Filter: SliderColumnFilter, //filter: filterGreaterThan,
          { Header: 'Salário Nominal', accessor: 'formattedSalary', Filter: NumberRangeColumnFilter, filter: 'between' }, //Filter: SliderColumnFilter, //filter: filterGreaterThan,
          { Header: 'Encargos', accessor: 'formattedCharges', Filter: NumberRangeColumnFilter, filter: 'between' }, //Filter: SliderColumnFilter //filter: filterGreaterThan,    
          { Header: 'Custo mensal', accessor: 'formattedMonthlyCost', Filter: NumberRangeColumnFilter, filter: 'between' },//Filter: SliderColumnFilter, //filter: filterGreaterThan
          { Header: 'Regime contrat.', accessor: 'contractMode' }, //Filter: SliderColumnFilter, //filter: filterGreaterThan,
          { Header: 'Situação', accessor: 'situation' }, //Filter: SliderColumnFilter, //filter: filterGreaterThan,
          { Header: 'Alocado', accessor: 'allocated' }, //Filter: SliderColumnFilter, //filter: filterGreaterThan,
          { Header: 'Localização', accessor: 'localization' }, //Filter: SliderColumnFilter, //filter: filterGreaterThan,
          { Header: 'Empresa', accessor: 'company' }, //Filter: SliderColumnFilter, //filter: filterGreaterThan,
          { Header: 'Validado', accessor: 'validated' } //Filter: SliderColumnFilter, //filter: filterGreaterThan,
        ]
      }
    ],
    []
  )


  //function fuzzyTextFilterFn(rows, id, filterValue) {
    //return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
  //}
  
  // Let the table remove the filter if the string is empty
  //fuzzyTextFilterFn.autoRemove = val => !val
  
  // Our table component
  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        //fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter(row => {
            const rowValue = row.values[id]
            return rowValue !== undefined
              ? String(rowValue)
                  .toLowerCase()
                  .startsWith(String(filterValue).toLowerCase())
              : true
          })
        },
      }),
      []
    )
  
    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    )
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,
    } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
      },
      useFilters, // useFilters!
      useGlobalFilter // useGlobalFilter!
    )
  
    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    const firstPageRows = rows.slice(0, 10)
  
    return (
      <>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                    {/* Render the columns filter UI */}
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td className="pointer" {...cell.getCellProps()} onClick={() => findPosition(row)}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        <br />
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
        <div>
            <button onClick={() => highlightPositions(rows)}>Destacar posições</button>
        </div>
      </>
    )
  }
  
  // Define a custom filter filter function!
  function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
      const rowValue = row.values[id]
      return rowValue >= filterValue
    })
  }
  
  // This is an autoRemove method on the filter function that
  // when given the new filter value and returns true, the filter
  // will be automatically removed. Normally this is just an undefined
  // check, but here, we want to remove the filter if it's not a number
  filterGreaterThan.autoRemove = val => typeof val !== 'number'




























    function getPosition(element) {
        var xPosition = 0;
        var yPosition = 0;

        while(element) {
            xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
            yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
            element = element.offsetParent;
        }

        return { x: xPosition, y: yPosition };
    }


    const findPosition = (row) => {
        document
            .querySelectorAll(".oc-node.highlighted")
            .forEach(el => {
            el.classList.remove("highlighted");
            });

        closeModal();
        expandPathToNode(row.original.id);
        setZoomValue(1);
        updateChartScale(1/zoomValue);


        setTimeout(
            function() {
                let node = document.querySelector("#" + row.original.id);
                node.classList.add("highlighted");

                let nodePosition = getPosition(node);

                let currentContainerWidth = container.current.offsetWidth;
                let currentContainerHeight = container.current.offsetHeight;

                let newX = ((currentContainerWidth/2) - nodePosition.x);
                let newY = ((currentContainerHeight/2) - nodePosition.y);
                
                console.log(nodePosition, newX, newY);

                let matrix = transform.split(",");
                matrix[0] = 1;
                matrix[5] = 1;
                matrix[12] = newX;
                matrix[13] = newY;
                setTransform("matrix(1,0,0,1," + newX + "," + newY + ")");
                
            }.bind(this),
            10
        ); 
    };

    const highlightPositions = (rows) => {
        document
            .querySelectorAll(".oc-node.highlighted")
            .forEach(el => {
            el.classList.remove("highlighted");
            });

        if (rows.length === 0){
            return;
        }

        closeModal();
        expandAll();
        setZoomValue(1);
        updateChartScale(1/zoomValue);

        setTimeout(
            function() {
                let positionIds = _.map(rows, 'original.id');
    
                for (const positionId of positionIds){
                    let node = document.querySelector("#" + positionId);
                    node.classList.add("highlighted");
                }

                let currentContainerWidth = container.current.offsetWidth;
                let currentContainerHeight = container.current.offsetHeight;

                let root = _.find(flatPositions, {originalSuperiorId: undefined});

                let node = document.querySelector("#" + root.id);
                let nodePosition = getPosition(node);
                
                let newX = ((currentContainerWidth/2) - nodePosition.x);
                let newY = ((currentContainerHeight/2) - nodePosition.y);
                
                let matrix = transform.split(",");
                matrix[0] = 1;
                matrix[5] = 1;
                matrix[12] = newX;
                matrix[13] = newY;
                setTransform("matrix(1,0,0,1," + newX + "," + newY + ")");
            }.bind(this),
            10
        );    
    };


    const propagateValidationToChildren = (node, validation) => {
        if (!node.children || node.children.length === 0){
            return;
        }
    
        for (const child of node.children){
            child.validated = validation;
            propagateValidationToChildren(child, validation);
        }
      };


    const togglePositionValidation = async (id, propagateToChildren) => {
        let node = _.find(flatPositions, {id: id});
        
        if (!node.validated || node.validated === '0' || node.validated === 0){
            node.validated = 1;
        } else {
            node.validated = 0;
        }

        if (propagateToChildren){
            propagateValidationToChildren(node, node.validated);
        }

        setDS({ ...dsDigger.ds });
    }



    const attachRel = (data, flags) => {
      data.relationship = flags + (data.children && data.children.length > 0 ? 1 : 0);
      if (data.children) {
        data.children.forEach(function(item) {
          attachRel(item, "1" + (data.children.length > 1 ? 1 : 0));
        });
      }
      return data;
    };

    const [ds, setDS] = useState(datasource);
    useEffect(() => {
      setDS(datasource);
    }, [datasource]);

    const dsDigger = new JSONDigger(datasource, "id", "children");

    const clickChartHandler = event => {
      if (!event.target.closest(".oc-node")) {
        if (onClickChart) {
          onClickChart();
        }
        selectNodeService.clearSelectedNodeInfo();
      }
    };

    const centralize = (previousChartWidth = chart.current.offsetWidth,
                        previousContainerWidth = container.current.offsetWidth) => {

      setTimeout(
          function() {
            let currentChartWidth = chart.current.offsetWidth;
            let currentContainerWidth = container.current.offsetWidth;

            let lastX = 0;
            let lastY = 0;
            if (transform !== "") {
              let matrix = transform.split(",");
              if (transform.indexOf("3d") === -1) {
                lastX = parseInt(matrix[4]);
                lastY = parseInt(matrix[5]);
              } else {
                lastX = parseInt(matrix[12]);
                lastY = parseInt(matrix[13]);
              }
            }

            let containerIncrease = currentContainerWidth - previousContainerWidth;
            let widthIncrease = (currentChartWidth - previousChartWidth) - containerIncrease;

            let newX = lastX - (widthIncrease/2);
            let newY = lastY;

            if (containerIncrease < 0 || isNaN(newX)){
              newX = 0;
            }

            if (widthIncrease === 0){
              newX = lastX;
            }

            if (transform === "") {
              if (transform.indexOf("3d") === -1) {
                setTransform("matrix(1,0,0,1," + newX + "," + newY + ")");
              } else {
                setTransform("matrix3d(1,0,0,0,0,1,0,0,0,0,1,0," + newX + ", " + newY + ",0,1)");
              }
            } else {
              let matrix = transform.split(",");
              if (transform.indexOf("3d") === -1) {
                matrix[4] = newX;
                matrix[5] = newY + ")";
              } else {
                matrix[12] = newX;
                matrix[13] = newY;
              }
              setTransform(matrix.join(","));
            }
          }.bind(this),
          1
      );
    }



    const panEndHandler = () => {
      setPanning(false);
      setCursor("default");
    };

    const panHandler = e => {
      let newX = 0;
      let newY = 0;
      if (!e.targetTouches) {
        // pand on desktop
        newX = e.pageX - startX;
        newY = e.pageY - startY;
      } else if (e.targetTouches.length === 1) {
        // pan on mobile device
        newX = e.targetTouches[0].pageX - startX;
        newY = e.targetTouches[0].pageY - startY;
      } else if (e.targetTouches.length > 1) {
        return;
      }

      if (transform === "") {
        if (transform.indexOf("3d") === -1) {
          setTransform("matrix(1,0,0,1," + newX + "," + newY + ")");
        } else {
          setTransform(
            "matrix3d(1,0,0,0,0,1,0,0,0,0,1,0," + newX + ", " + newY + ",0,1)"
          );
        }
      } else {
        let matrix = transform.split(",");
        if (transform.indexOf("3d") === -1) {
          matrix[4] = newX;
          matrix[5] = newY + ")";
        } else {
          matrix[12] = newX;
          matrix[13] = newY;
        }
        setTransform(matrix.join(","));
      }
    };

    const panStartHandler = e => {
      if (e.target.closest(".oc-node")) {
        setPanning(false);
        return;
      } else {
        setPanning(true);
        setCursor(" move");
      }
      let lastX = 0;
      let lastY = 0;
      if (transform !== "") {
        let matrix = transform.split(",");
        if (transform.indexOf("3d") === -1) {
          lastX = parseInt(matrix[4]);
          lastY = parseInt(matrix[5]);
        } else {
          lastX = parseInt(matrix[12]);
          lastY = parseInt(matrix[13]);
        }
      }

      if (!e.targetTouches) {
        // pand on desktop
        setStartX(e.pageX - lastX);
        setStartY(e.pageY - lastY);
      } else if (e.targetTouches.length === 1) {
        // pan on mobile device
        setStartX(e.targetTouches[0].pageX - lastX);
        setStartY(e.targetTouches[0].pageY - lastY);
      } else if (e.targetTouches.length > 1) {
        return;
      }
    };

    const updateChartScale = newScale => {
      let matrix = [];
      let targetScale = 1;
      if (transform === "") {
        let transform = "matrix(" + newScale + ", 0, 0, " + newScale + ", 0, 0)";
        setTransform(transform);
        return transform;
      } else {
        matrix = transform.split(",");
        if (transform.indexOf("3d") === -1) {
          targetScale = Math.abs(window.parseFloat(matrix[3]) * newScale);
          matrix[0] = "matrix(" + targetScale;
          matrix[3] = targetScale;
          let transform = matrix.join(",");
          setTransform(transform);
          return transform;
        } else {
          targetScale = Math.abs(window.parseFloat(matrix[5]) * newScale);
          matrix[0] = "matrix3d(" + targetScale;
          matrix[5] = targetScale;
          let transform = matrix.join(",");
          setTransform(transform);
          return transform;
        }
      }
    };

    const zoomHandler = e => {
      let newScale = 1 + (e.deltaY > 0 ? -0.2 : 0.2);
      updateChartScale(newScale);
    };

    const exportPDF = (canvas, exportFilename) => {
      const canvasWidth = Math.floor(canvas.width);
      const canvasHeight = Math.floor(canvas.height);
      const doc =
        canvasWidth > canvasHeight
          ? new jsPDF({
              orientation: "landscape",
              unit: "px",
              format: [canvasWidth, canvasHeight]
            })
          : new jsPDF({
              orientation: "portrait",
              unit: "px",
              format: [canvasHeight, canvasWidth]
            });
      doc.addImage(canvas.toDataURL("image/jpeg", 1.0), "JPEG", 0, 0);
      doc.save(exportFilename + ".pdf");
    };

    const exportPNG = (canvas, exportFilename) => {
      const isWebkit = "WebkitAppearance" in document.documentElement.style;
      const isFf = !!window.sidebar;
      const isEdge =
        navigator.appName === "Microsoft Internet Explorer" ||
        (navigator.appName === "Netscape" &&
          navigator.appVersion.indexOf("Edge") > -1);

      if ((!isWebkit && !isFf) || isEdge) {
        window.navigator.msSaveBlob(canvas.msToBlob(), exportFilename + ".png");
      } else {
        setDataURL(canvas.toDataURL());
        setDownload(exportFilename + ".png");
        downloadButton.current.click();
      }
    };

    const changeHierarchy = async (draggedItemData, dropTargetId) => {
      if (dropTargetId === 'CUSTOM_CHILD'){
        return;
      }

      if (draggedItemData.id === 'CUSTOM_CHILD') {
        saveActionHistory('Alterar superior da equipe');
        await dsDigger.removeNodes(_.map(draggedItemData.leaves, 'id'));
        await dsDigger.addChildren(dropTargetId, draggedItemData.leaves);
        onDropNode(datasource);
        setDS({ ...dsDigger.ds });
      } else {
        saveActionHistory('Alterar superior da posição: ' + draggedItemData.originalId);
        await dsDigger.removeNode(draggedItemData.id);
        await dsDigger.addChildren(dropTargetId, draggedItemData);
        onDropNode(datasource);
        setDS({ ...dsDigger.ds });
      }
    };

    const changeZoom = (newZoom) => {
      setZoomValue( zoomValue + (zoomValue * newZoom));
      updateChartScale(1 + newZoom);
    }

    const autoFit = () => {
      let currentChartWidth = chart.current.offsetWidth;
      let currentChartHeight = chart.current.offsetHeight;

      let currentContainerWidth = container.current.offsetWidth;
      let currentContainerHeight = container.current.offsetHeight;

      let newScale = (currentContainerWidth / currentChartWidth) / zoomValue;

      setZoomValue((currentContainerWidth / currentChartWidth));
      let transform = updateChartScale(newScale);

      let newY = ((currentChartHeight-currentContainerHeight)*newScale)*-1;

      let matrix = transform.split(",");
      if (transform.indexOf("3d") === -1) {
        matrix[4] = (currentChartWidth-currentContainerWidth)/2*-1;
        matrix[5] = newY;
      } else {
        matrix[12] = (currentChartWidth-currentContainerWidth)/2*-1;
        matrix[13] = newY;
      }
      setTransform(matrix.join(","));

    }

    const populateSettingsByNode = (node, settings, defaultOption) => {    
        if (node.children) {
            node.children.forEach(function(child) {
                settings[child.id] = {bottomEdgeExpanded: defaultOption, topEdgeExpanded: defaultOption, multiplePositionMode: defaultOption};
                populateSettingsByNode(child, settings, defaultOption);
            });
        }
        return settings;
    };

    const initializeSettingsByNode = () => { 
        let settingsByNode = {};
        settingsByNode[datasource.id] = {bottomEdgeExpanded: true, topEdgeExpanded: true, multiplePositionMode: true};
        
        populateSettingsByNode(datasource, settingsByNode, false);

        setSettingsByNode(settingsByNode);
    }

    const expandPathToNode = (nodeId) => {
        let settingsByNodeUpdate = settingsByNode;

        let node = _.find(flatPositions, {id: nodeId});
        let parent = _.find(flatPositions, {id: node.superiorId});

        if (parent) {
            settingsByNodeUpdate[parent.id].multiplePositionMode = true;
        }

        while (parent){
            settingsByNodeUpdate[parent.id].bottomEdgeExpanded = true;
            settingsByNodeUpdate[parent.id].topEdgeExpanded = true;
            parent = _.find(flatPositions, {id: parent.superiorId});
        }

        setSettingsByNode(settingsByNodeUpdate);
    }

    const expandAll = () => {
    
        let settingsByNodeUpdate = settingsByNode;
        for (const nodeKey of _.keys(settingsByNodeUpdate)){
            settingsByNodeUpdate[nodeKey].bottomEdgeExpanded = true;
            settingsByNodeUpdate[nodeKey].topEdgeExpanded = true;
        }

        setSettingsByNode(settingsByNodeUpdate);
      
      let previousChartWidth = chart.current.offsetWidth;
      let previousContainerWidth = container.current.offsetWidth;

      document
        .querySelectorAll(".oc-node.hidden, .oc-node.isChildrenCollapsed, ul.hidden")
        .forEach(el => {
          el.classList.remove(
              "hidden",
              "isSiblingsCollapsed",
              "isAncestorsCollapsed",
              "isChildrenCollapsed"
          );
        });

      centralize(previousChartWidth, previousContainerWidth);
      
    }

    const collapseAll = () => {
      let previousChartWidth = chart.current.offsetWidth;
      let previousContainerWidth = container.current.offsetWidth;

        let settingsByNodeUpdate = settingsByNode;
        for (const nodeKey of _.keys(settingsByNodeUpdate)){
            settingsByNodeUpdate[nodeKey].bottomEdgeExpanded = false;
            settingsByNodeUpdate[nodeKey].topEdgeExpanded = false;
        }

        setSettingsByNode(settingsByNodeUpdate);

      document
          .querySelectorAll("ul")
          .forEach(el => {
            if (!el.parentElement.classList.contains('myChart')){
              el.classList.add(
                  "hidden"
              );
            }
          });

      document
          .querySelectorAll(".oc-node")
          .forEach(el => {
            el.classList.add(
                "isChildrenCollapsed"
            );
          });

      centralize(previousChartWidth, previousContainerWidth);
    }

    const expandAllTeams = () => {
        let previousChartWidth = chart.current.offsetWidth;
        let previousContainerWidth = container.current.offsetWidth;

        let updatedSettingsByNode = {};
        for (const nodeKey of _.keys(settingsByNode)){
            updatedSettingsByNode[nodeKey] = {
                bottomEdgeExpanded: settingsByNode[nodeKey].bottomEdgeExpanded,
                topEdgeExpanded: settingsByNode[nodeKey].topEdgeExpanded,
                multiplePositionMode: true
            }
        }
        setSettingsByNode(updatedSettingsByNode);

        centralize(previousChartWidth, previousContainerWidth);
    }
  
    const collapseAllTeams = () => {
        let previousChartWidth = chart.current.offsetWidth;
        let previousContainerWidth = container.current.offsetWidth;

        let updatedSettingsByNode = {};
        for (const nodeKey of _.keys(settingsByNode)){
            updatedSettingsByNode[nodeKey] = {
                bottomEdgeExpanded: settingsByNode[nodeKey].bottomEdgeExpanded,
                topEdgeExpanded: settingsByNode[nodeKey].topEdgeExpanded,
                multiplePositionMode: false
            }
        }

        setSettingsByNode(updatedSettingsByNode);

        centralize(previousChartWidth, previousContainerWidth);
    }

    useImperativeHandle(ref, () => ({
      exportToPNG: () => {
        let exportFilename = "OrgChart";
        setTransform("matrix(1,0,0,1,0,0)");
        setZoomValue(1);
        setExporting(true);
        setTimeout(
            function() {
                const originalScrollLeft = chart.current.scrollLeft;
                chart.current.scrollLeft = 0;
                const originalScrollTop = chart.current.scrollTop;
                chart.current.scrollTop = 0;
                html2canvas(chart.current, {
                width: chart.current.clientWidth,
                height: chart.current.clientHeight,
                onclone: function(clonedDoc) {
                    clonedDoc.querySelector(".orgchart").style.background = "none";
                    clonedDoc.querySelector(".orgchart").style.transform = "";
                }
                }).then(
                canvas => {
                    exportPNG(canvas, exportFilename);
                    setExporting(false);
                    chart.current.scrollLeft = originalScrollLeft;
                    chart.current.scrollTop = originalScrollTop;
                },
                () => {
                    setExporting(false);
                    chart.current.scrollLeft = originalScrollLeft;
                    container.current.scrollTop = originalScrollTop;
                }
                );
            }.bind(this),
            10
        );         
      }
    }));

    const updateSettingsByNode = (id, settings) => {
      const newState = Object.assign({}, settingsByNode);
      newState[id] = settings;

      setSettingsByNode(newState);
    }

    useEffect(() => {
        initializeSettingsByNode();
    }, []);


    return (
        <>
          <div className="logo-holder">
            <img src={require('./../assets/logo_linear.png')} width="300px" alt="logo"/>
          </div>

          <div
            ref={container}
            className={"orgchart-container " + containerClass}
            onWheel={zoom ? zoomHandler : undefined}
            onMouseUp={pan && panning ? panEndHandler : undefined}>
            
            <div className="menu-holder" id="menu-holder">
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    className="modal-content"
                    overlayClassName="modal-overlay">
                    <button onClick={closeModal}>Fechar</button>
                    <div>
                        <Table columns={columns} data={flatPositions} />
                    </div>
                </Modal>
                
                <div className="menu-item" onClick={() => changeZoom(0.2)}>Zoom +</div>
                <div className="menu-item" onClick={() => changeZoom(-0.2)}>Zoom -</div>
                <div className="menu-item" onClick={() => expandAll()}>Exibir todos</div>
                <div className="menu-item" onClick={() => collapseAll()}>Esconder todos</div>
                <div className="menu-item" onClick={() => expandAllTeams()}>Exibir equipes</div>
                <div className="menu-item" onClick={() => collapseAllTeams()}>Esconder equipes</div>
                <div className="menu-item" onClick={() => autoFit()}>Auto fit</div>
                <div className="menu-item" onClick={() => openModal()}>Buscar</div>
                {actionHistory && (actionHistory.length > 0) && (
                    <div className="menu-item" onClick={() => undoLastAction()} data-tip={actionHistory[actionHistory.length-1].actionName}>
                    <ReactTooltip />Desfazer
                    </div>
                )}
                <div className="menu-item" onClick={()=> window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=DyNe3WXBxEmVf-IDRY__qxtd9kZF479CqeRn8pOK-0BURVlUOUpUMVpYNkhQQ0lIOThUWUczNjVJRC4u", "_blank")}>Feedbacks</div>
            </div>


            <div
              ref={chart}
              className={"orgchart " + chartClass}
              style={{ transform: transform, cursor: cursor }}
              onClick={clickChartHandler}
              onMouseDown={pan ? panStartHandler : undefined}
              onMouseMove={pan && panning ? panHandler : undefined}
            >
              <ul>
                <ChartNode
                  datasource={attachRel(ds, "00")}
                  NodeTemplate={NodeTemplate}
                  togglePositionValidation={togglePositionValidation}
                  updateSettingsByNode={updateSettingsByNode}
                  draggable={draggable}
                  collapsible={collapsible}
                  centralize={centralize}
                  multipleSelect={multipleSelect}
                  settingsByNode={settingsByNode}
                  changeHierarchy={changeHierarchy}
                  onClickMultipleNode={onClickMultipleNode}
                  onClickNode={onClickNode}
                  onDropNode={onDropNode}            
                />
              </ul>
            </div>
            <a className="oc-download-btn hidden" ref={downloadButton} href={dataURL} download={download}>
              &nbsp;
            </a>
            <div className={`oc-mask ${exporting ? "" : "hidden"}`}>
              <i className="oci oci-spinner spinner"></i>
            </div>
          </div>
      </>
    );
  }
);

ChartContainer.propTypes = propTypes;
ChartContainer.defaultProps = defaultProps;

export default ChartContainer;
