import React, {
    useState,
    useEffect
  } from "react";
import PropTypes from "prop-types";
import "./node-template.css";
import _ from 'lodash';
import ChartNode from "../components/ChartNode";
import NodeTemplate from "./position-node";

const propTypes = {
  nodeData: PropTypes.object.isRequired,
  parentToggleMultiplePositionMode: PropTypes.func,
  multiplePositionMode: PropTypes.bool,

  datasource: PropTypes.object,
  
  draggable: PropTypes.bool,
  collapsible: PropTypes.bool,
  multipleSelect: PropTypes.bool,
  changeHierarchy: PropTypes.func,
  centralize: PropTypes.func,
  onClickNode: PropTypes.func,
  onClickMultipleNode: PropTypes.func,
  onDropNode: PropTypes.func,
  onDragStart: PropTypes.func,
  updateSettingsByNode: PropTypes.func,
  settingsByNode: PropTypes.object,
  togglePositionValidation: PropTypes.func
};

const MultiplePositionNode = ({ 
    nodeData, 
    parentToggleMultiplePositionMode, 
    multiplePositionMode,

    togglePositionValidation,
    datasource,
    
    draggable,
    collapsible,
    multipleSelect,
    changeHierarchy,
    centralize,
    onClickNode,
    onClickMultipleNode,
    onDragStart,
    onDropNode,
    updateSettingsByNode,
    settingsByNode
}) => {
  const selectNode = () => {
    //alert("Hi All. I'm " + nodeData.name + ". I'm a " + nodeData.title + ".");
  };

  return (
    <div className="node-position-multiple"  onClick={selectNode}>

        <span onClick={parentToggleMultiplePositionMode} className="multiple-position-toggle" onDragStart={onDragStart} draggable={draggable}>
            ({nodeData.leaves.length}) {(multiplePositionMode ? "Equipe [-]" : "Equipe [+]")}
        </span>
        
        {nodeData && nodeData.leaves.map(node => (
            <ul key={node.id} className={multiplePositionMode ? "" : "hidden"}>
            <ChartNode
                datasource={node}
                NodeTemplate={NodeTemplate}
                updateSettingsByNode={updateSettingsByNode}
                togglePositionValidation={togglePositionValidation}
                settingsByNode={settingsByNode}
                id={node.id}
                key={node.id}
                draggable={true}
                collapsible={collapsible}
                changeHierarchy={changeHierarchy}
                centralize={centralize}
                onClickNode={onClickNode}
                onDropNode={onDropNode}
            />
            </ul>
        ))}
    
    </div>
  );
};

MultiplePositionNode.propTypes = propTypes;

export default MultiplePositionNode;
