import React, { useState } from "react";
import PropTypes from "prop-types";
import "./node-template.css";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';

const propTypes = {
  nodeData: PropTypes.object.isRequired,
  togglePositionValidation: PropTypes.func,
  onDragStart: PropTypes.func,
  draggable: PropTypes.bool
};

const PositionNode = ({ nodeData, togglePositionValidation, onDragStart, draggable}) => {
  const selectNode = () => {
    //alert("Hi All. I'm " + nodeData.name + ". I'm a " + nodeData.title + ".");
  };

  const togglePositionValidationPrep = () => {
    if (nodeData.children && nodeData.children.length){
        positionValidationAlert();
    } else {
        togglePositionValidation(nodeData.id);
    }
  };

  const positionValidationAlert = () => {
    confirmAlert({
      message: 'Validar:',
      buttons: [
        {
          label: 'Somente esta posição',
          onClick: () => {
            togglePositionValidation(nodeData.id);
          }
        },
        {
          label: 'Toda hieraquia',
          onClick: () => {
            togglePositionValidation(nodeData.id, true);
          }
        }
      ]
    });
  };

  return (
    <div className="node-position" onClick={selectNode} onDragStart={onDragStart} draggable={draggable}>
        <div onClick={togglePositionValidationPrep} className={'position-validation ' + (nodeData.validated? 'position-validated' : 'position-not-validated')}></div>
        <div className="position-code">
            <span>{nodeData.originalId}</span> 
        </div>
        <div className="position">
            <div className="position-title">
                <span>{nodeData.title}</span> 
            </div>
        </div>
    
        <div className="fullname">{nodeData.name}</div>
        <div className="hierarchy-info">{(nodeData.children && nodeData.children.length > 0) && (nodeData.children.length + "/" + nodeData.totalSubordinates)}</div>
    </div>
  );
};

PositionNode.propTypes = propTypes;

export default PositionNode;
