import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { dragNodeService, selectNodeService } from "./service";
import MultiplePositionNodeTemplate from "../custom-node-chart/multiple-position-node";
import "./ChartNode.css";

const propTypes = {
  datasource: PropTypes.object,
  NodeTemplate: PropTypes.elementType,
  draggable: PropTypes.bool,
  collapsible: PropTypes.bool,
  multipleSelect: PropTypes.bool,
  changeHierarchy: PropTypes.func,
  centralize: PropTypes.func,
  onClickNode: PropTypes.func,
  onClickMultipleNode: PropTypes.func,
  onDropNode: PropTypes.func,
  updateSettingsByNode: PropTypes.func,
  togglePositionValidation: PropTypes.func,
  parentToggleMultiplePositionMode: PropTypes.func,
  settingsByNode: PropTypes.object,
};

const defaultProps = {
  draggable: false,
  collapsible: true,
  multipleSelect: false
};

const ChartNode = ({
  datasource,
  NodeTemplate,
  draggable,
  collapsible,
  multipleSelect,
  changeHierarchy,
  centralize,
  onClickNode,
  onDropNode,
  updateSettingsByNode,
  togglePositionValidation,
  settingsByNode
}) => {
  const node = useRef();

  const [isChildrenCollapsed, setIsChildrenCollapsed] = useState(true);
  const [topEdgeExpanded, setTopEdgeExpanded] = useState();
  const [allowedDrop, setAllowedDrop] = useState(false);
  const [selected, setSelected] = useState(false);
  const [bottomEdgeExpanded, setBottomEdgeExpanded] = useState();

  const nodeClass = [
    "oc-node",
    !!(settingsByNode[datasource.id] && settingsByNode[datasource.id].bottomEdgeExpanded) ? "" : "isChildrenCollapsed",
    allowedDrop ? "allowedDrop" : "",
    selected ? "selected" : ""
  ]
    .filter(item => item)
    .join(" ");

  useEffect(() => {
    const subs1 = dragNodeService.getDragInfo().subscribe(draggedInfo => {
        if (draggedInfo && draggedInfo.draggedNodeId === -1 && node.current.id){
            setAllowedDrop(false);
            return;
        }
      if (draggedInfo) {
        setAllowedDrop(
          !document
            .querySelector("#" + draggedInfo.draggedNodeId)
            .closest("li")
            .querySelector("#" + node.current.id)
            ? true
            : false
        );
      } else {
        setAllowedDrop(false);
      }
    });

    const subs2 = selectNodeService
      .getSelectedNodeInfo()
      .subscribe(selectedNodeInfo => {
        if (selectedNodeInfo) {
          if (multipleSelect) {
            if (selectedNodeInfo.selectedNodeId === datasource.id) {
              setSelected(true);
            }
          } else {
            setSelected(selectedNodeInfo.selectedNodeId === datasource.id);
          }
        } else {
          setSelected(false);
        }
      });

    return () => {
      subs1.unsubscribe();
      subs2.unsubscribe();
    };
  }, [multipleSelect, datasource]);

  const addArrows = e => {
    const node = e.target.closest("li");
    const parent = node.parentNode.closest("li");
    const isAncestorsCollapsed =
      node && parent
        ? parent.firstChild.classList.contains("hidden")
        : undefined;

    setTopEdgeExpanded(!isAncestorsCollapsed);
    setBottomEdgeExpanded(!isChildrenCollapsed);
  };

  const removeArrows = () => {
    setTopEdgeExpanded(undefined);
    setBottomEdgeExpanded(undefined);
  };

  const toggleAncestors = actionNode => {
    let node = actionNode.parentNode.closest("li");
    if (!node) return;
    const isAncestorsCollapsed = node.firstChild.classList.contains("hidden");
    if (isAncestorsCollapsed) {
      actionNode.classList.remove("isAncestorsCollapsed");
      node.firstChild.classList.remove("hidden");
      toggleSiblings(actionNode);
    } else {
    
      
      actionNode.classList.add(
        ...("isAncestorsCollapsed").split(" ")
      );
      node.firstChild.classList.add("hidden");
      if (node.parentNode.closest("li") &&!node.parentNode.closest("li").firstChild.classList.contains("hidden")) {
        toggleAncestors(node);
      }
    }
  };

  const topEdgeClickHandler = e => {
    e.stopPropagation();
    setTopEdgeExpanded(!topEdgeExpanded);

    let isTopExpanded = !!(settingsByNode[datasource.id] && settingsByNode[datasource.id].topEdgeExpanded);
    let isBottomExpanded = !!(settingsByNode[datasource.id] && settingsByNode[datasource.id].bottomEdgeExpanded);
    let isMultiplePositionMode = !!(settingsByNode[datasource.id] && settingsByNode[datasource.id].multiplePositionMode);
    updateSettingsByNode(datasource.id, {bottomEdgeExpanded: isBottomExpanded, topEdgeExpanded: !isTopExpanded, multiplePositionMode: isMultiplePositionMode});

    let actionNode = e.target.closest("li");
    centralize();
    toggleAncestors(actionNode);
  };

  const bottomEdgeClickHandler = e => {
    e.stopPropagation();
    setIsChildrenCollapsed(!isChildrenCollapsed);

    let isTopExpanded = !!(settingsByNode[datasource.id] && settingsByNode[datasource.id].topEdgeExpanded);
    let isBottomExpanded = !!(settingsByNode[datasource.id] && settingsByNode[datasource.id].bottomEdgeExpanded);
    let isMultiplePositionMode = !!(settingsByNode[datasource.id] && settingsByNode[datasource.id].multiplePositionMode);
    updateSettingsByNode(datasource.id, {bottomEdgeExpanded: !isBottomExpanded, topEdgeExpanded: isTopExpanded, multiplePositionMode: isMultiplePositionMode});

    centralize();
  };

  const toggleSiblings = actionNode => {
    let node = actionNode.previousSibling;
    const isSiblingsCollapsed = Array.from(
      actionNode.parentNode.children
    ).some(item => item.classList.contains("hidden"));
    actionNode.classList.toggle("isSiblingsCollapsed", !isSiblingsCollapsed);
    while (node) {
      if (isSiblingsCollapsed) {
        node.classList.remove("hidden");
      } else {
        node.classList.add("hidden");
      }
      node = node.previousSibling;
    }
    node = actionNode.nextSibling;
    while (node) {
      if (isSiblingsCollapsed) {
        node.classList.remove("hidden");
      } else {
        node.classList.add("hidden");
      }
      node = node.nextSibling;
    }
    const isAncestorsCollapsed = actionNode.parentNode
      .closest("li")
      .firstChild.classList.contains("hidden");
    if (isAncestorsCollapsed) {
      toggleAncestors(actionNode);
    }
  };

  const filterAllowedDropNodes = id => {
    dragNodeService.sendDragInfo(id);
  };

  const parentToggleMultiplePositionModeFunction = () => {
    let isTopExpanded = !!(settingsByNode[datasource.originalId] && settingsByNode[datasource.originalId].topEdgeExpanded);
    let isBottomExpanded = !!(settingsByNode[datasource.originalId] && settingsByNode[datasource.originalId].bottomEdgeExpanded);
    let isMultiplePositionMode = !!(settingsByNode[datasource.originalId] && settingsByNode[datasource.originalId].multiplePositionMode);
    updateSettingsByNode(datasource.originalId, {bottomEdgeExpanded: isBottomExpanded, topEdgeExpanded: isTopExpanded, multiplePositionMode: !isMultiplePositionMode});

    centralize();
  };

  const clickNodeHandler = event => {
    if (onClickNode) {
      onClickNode(datasource);
    }

    selectNodeService.sendSelectedNodeInfo(datasource.id);
  };

  const dragstartHandler = event => {
    const copyDS = { ...datasource };
    delete copyDS.relationship;
    event.dataTransfer.setData("text/plain", JSON.stringify(copyDS));
    // highlight all potential drop targets
    filterAllowedDropNodes(node.current.id);
  };

  const dragoverHandler = event => {
    // prevent default to allow drop
    document
        .querySelectorAll(".oc-node.highlighted")
        .forEach(el => {
          el.classList.remove("highlighted");
        });

    if (!event.currentTarget.classList.contains("allowedDrop")) {
      return;
    }

    document
        .querySelector("#" + event.currentTarget.id)
        .classList.add("highlighted");

    event.preventDefault();
  };

  const dragendHandler = () => {
    // reset background of all potential drop targets
    dragNodeService.clearDragInfo();
    splitChildrenReferences();
  };

  const dropHandler = event => {
    if (!event.currentTarget.classList.contains("allowedDrop")) {
      return;
    }
    dragNodeService.clearDragInfo();
    
    changeHierarchy(JSON.parse(event.dataTransfer.getData("text/plain")), event.currentTarget.id);
  };


  const splitChildrenReferences = () => {
    if (!datasource.children || datasource.children.length === 0){
        datasource.mixedChildren = datasource.children;
        return;
    }

    let leaves = [];
    
    let mixedChildren = []; 

    for (const child of datasource.children){
        if (child.children && child.children.length > 0){
            child.multiple = false;        
            mixedChildren.push(child);
        } else {
            leaves.push(child);
        }
    }
    
    if (leaves.length === 1){
        mixedChildren = datasource.children;
    } else if (leaves.length > 1) {
        let customChild = {
            id: 'CUSTOM_CHILD',
            originalId: datasource.id,
            multiple: true,
            leaves: leaves,
            children: []
          };
      
          mixedChildren.push(customChild);
    }
    
    datasource.mixedChildren = mixedChildren;
  }

  splitChildrenReferences();


  return (
    <li className="oc-hierarchy" key={node.id}>
      <div
        ref={node}
        id={datasource.id}
        key={datasource.id}
        className={nodeClass}
        draggable={draggable ? "true" : undefined}
        onClick={clickNodeHandler}
        onDragOver={dragoverHandler}
        onDragEnd={dragendHandler}
        onDrop={dropHandler}
        onMouseEnter={addArrows}
        onMouseLeave={removeArrows}
      >
          

        {NodeTemplate ? (
          <NodeTemplate 
            nodeData={datasource}
            parentToggleMultiplePositionMode={parentToggleMultiplePositionModeFunction}
            togglePositionValidation={togglePositionValidation}
            multiplePositionMode={(settingsByNode[datasource.originalId] ? settingsByNode[datasource.originalId].multiplePositionMode : false)}
            draggable={draggable}
            collapsible={collapsible}
            multipleSelect={multipleSelect}
            changeHierarchy={changeHierarchy}
            centralize={centralize}
            onClickNode={onClickNode}
            onDropNode={onDropNode}
            onDragStart={dragstartHandler}
            updateSettingsByNode={updateSettingsByNode}
            settingsByNode={settingsByNode}          
            />
        ) : (
          <>
            <div className="oc-heading">
              {datasource.relationship &&
                datasource.relationship.charAt(2) === "1" && (
                  <i className="oci oci-leader oc-symbol" />
                )}
              {datasource.name}
            </div>
            <div className="oc-content">{datasource.title}</div>
          </>
        )}
        {collapsible &&
          datasource.relationship &&
          datasource.relationship.charAt(0) === "1" && (
            <i
              className={`oc-edge verticalEdge topEdge oci ${
                  (settingsByNode[datasource.id] && settingsByNode[datasource.id].topEdgeExpanded)
                  ? "oci-chevron-down"
                  : "oci-chevron-up"
              }`}
              onClick={topEdgeClickHandler}
            />
          )}
        {collapsible &&
          datasource.relationship &&
          datasource.relationship.charAt(2) === "1" && (
            <i
              className={`oc-edge verticalEdge bottomEdge oci ${
                  (settingsByNode[datasource.id] && settingsByNode[datasource.id].bottomEdgeExpanded)
                  ? "oci-chevron-up"
                  : "oci-chevron-down"
              }`}
              onClick={bottomEdgeClickHandler}
            />
          )}
      </div>

      {datasource.mixedChildren && datasource.mixedChildren.length > 0 && (
        <ul className={!!(settingsByNode[datasource.id] && settingsByNode[datasource.id].bottomEdgeExpanded) ? "" : "hidden"}>
          {datasource.mixedChildren.map(node => (
              
              !node.multiple ? (
                <ChartNode
                    datasource={node}
                    NodeTemplate={NodeTemplate}
                    updateSettingsByNode={updateSettingsByNode}
                    togglePositionValidation={togglePositionValidation}
                    settingsByNode={settingsByNode}
                    id={node.id}
                    key={node.id}
                    draggable={draggable}
                    collapsible={collapsible}
                    changeHierarchy={changeHierarchy}
                    centralize={centralize}
                    onDragStart={dragstartHandler}
                    onClickNode={onClickNode}
                    onDropNode={onDropNode}
                />
                ) : (
                <ChartNode
                    datasource={node}
                    NodeTemplate={MultiplePositionNodeTemplate}
                    updateSettingsByNode={updateSettingsByNode}
                    parentToggleMultiplePositionMode={parentToggleMultiplePositionModeFunction}
                    multiplePositionMode={(settingsByNode[datasource.id] ? settingsByNode[datasource.id].multiplePositionMode : false)}
                    togglePositionValidation={togglePositionValidation}
                    settingsByNode={settingsByNode}
                    id={node.id}
                    key={node.id}
                    draggable={draggable}
                    collapsible={collapsible}
                    changeHierarchy={changeHierarchy}
                    centralize={centralize}
                    onClickNode={onClickNode}
                    onDropNode={onDropNode}
                />
            )
          ))}
        </ul>
      )}

    </li>
  );
};

ChartNode.propTypes = propTypes;
ChartNode.defaultProps = defaultProps;

export default ChartNode;
